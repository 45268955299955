
const postBlob = (url,blob,cB) => {
    var x = new XMLHttpRequest();
        x.onreadystatechange = () => {
            if ( x.readyState == 4 && x.status == 200 ) {
                var resp = JSON.parse(x.responseText);
                if ( resp.error ) return Alert.error(resp.error);
                cB(resp)
            }
        }
        x.onerror = e => console.log('error',e);
        x.open('POST',url);
        x.send(blob)
} 




var ws;
var reconnect = 0;
var q = 0;
var Q = {};
var now;
var interval;

const WebSoc = (url,cBs) => {
    
    console.log('WebSoc connect',{ url })
    
    ws = null;

    ws = new WebSocket(url);
    ws.id = Date.now()+':'+parseInt(Math.random() * 200)

    ws.onopen = () => {
        
        console.log('open',ws.id)

        WebSoc.open = true;
        
        now = Date.now();

        reconnect = 0;

        clearInterval(interval);

        interval = setInterval(() => { ws.readyState == 1 ? ws.send("1") : clearInterval(interval) },19000);

        typeof cBs.onopen == 'function' && cBs.onopen(ws);
    
    }

    ws.onerror = e => console.log('WebSoc error',e)

    ws.SEND = ( msg, cB, data  ) => {
        //console.log('send',{ msg, cB, data });
        if ( ws.readyState != 1 ) return console.log('!open');

        if ( typeof msg == 'string' ) {
            msg = { t: msg, data: cB || {} }
            Q[msg.data.q = ++q] = data;
            ws.send(JSON.stringify(msg));
        } else {
            msg.data = msg.data || {};
            Q[msg.data.q = ++q] = cB; 
            ws.send(JSON.stringify(msg)) 
        }
    }

    ws.onclose = () => {
        console.log('close',ws.id)
        WebSoc.open = false;
        setTimeout(() => {
            WebSoc(url,cBs);
            if ( reconnect > 3000 ) reconnect = 3000;
        },reconnect += 50);       
    }

    ws.onerror = e => {
        console.log('error',e)
    }

    ws.onmessage = (data) => {
        if ( data.data == "1" ) { return; }
        //if ( window.ws != ws ) return alert('window.ws != ws');
        var msg = JSON.parse(data.data);

        var error = msg.data && msg.data.error;

        if ( error ) {
            cBs.onerror(error);
            return typeof Q[msg.q] == 'function' && Q[msg.q](msg.data);
        }

        if ( typeof Q[msg.q] == 'function' ) { return Q[msg.q](msg.data) };

        if ( typeof Service[msg.t] == 'function' ) Service[msg.t](msg.data);

        if ( typeof cBs.onmessage == 'function' ) { return cBs.onmessage(msg) };

    }

    var Service = {
        saveGuid (data) {
            
            var { _id, id } = data;

            if ( _id && id ) {
                try {
                    localStorage._id = _id;
                    localStorage.id = id;
                } catch(e) {

                }
            }

        } 
    }
}

WebSoc.SEND = ( msg, cB, data ) => { 
    
    if ( !ws ) return delay();
    if ( ws.readyState != 1 ) return delay();

    ws.SEND(msg,cB,data) 

    function delay() {
        console.log('delay')
        setTimeout(() => {
            WebSoc.SEND( msg, cB )
        },100);
    }
};

const DB = {
    SAVE: ( data,cB ) => WebSoc.SEND('save',data,cB),
    UPDATE: ( data,cB ) => WebSoc.SEND('update',data,cB),
    SEARCH: ( data, cB ) => WebSoc.SEND('search',data,cB),
    GET: ( data, cB ) => WebSoc.SEND('get',data,cB),
    COUNT: ( data, cB ) => WebSoc.SEND('count',data,cB),
    AGG: ( data, cB ) => WebSoc.SEND('agg',data,cB),
    DELETE: ( data, cB ) => WebSoc.SEND('delete',data,cB)
}

export { WebSoc, DB, postBlob };