<template>
  <div class="home">
    <img alt="Vue logo" style="width:100vw;height:100vh;object-fit: cover" src="../assets/nainoa-shizuru-NcdG9mK3PBY-unsplash.jpg">
    <div class="counter" style="overflow-y: scroll;">
      <div class="row first">
          <div class="block">
            <p class="digit">{{ state.days }}</p>
            <p class="text">Days</p>
        </div>
        <div class="block">
            <p class="digit">{{ state.hours }}</p>
            <p class="text">Hours</p>
        </div>
      </div>
      <div class="row second">
        <div class="block">
            <p class="digit">{{ state.minutes }}</p>
            <p class="text">Minutes</p>
        </div>
        <div class="block">
            <p class="digit">{{ state.seconds }}</p>
            <p class="text">Seconds</p>
        </div>
      </div>
    </div>
    <q-btn color="primary" label="register" style="position:absolute;top:10px;right: 10px" @click="register" />
    <CreateAccount v-if="state.createAccount" @exit="state.createAccount = false" />
    <component v-for="(component,i) in store.state.components" :key="i" :is="component.component" :data="component.data" @exit="removeComponent(component)"></component>
  </div>
</template>

<script>

import { reactive, watch, ref } from 'vue';
import CreateAccount from '../components/createAccount.vue'
import { WebSoc } from '@/utils/Comms';
import { useStore } from 'vuex';

export default {
  name: 'HomeView',
  components: { CreateAccount },
  setup ( props, context ) {

    const store = useStore();

    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = 'https://'+location.host+'/img/icons/favicon.ico';
        document.head.appendChild(link);

    const date = new Date('2024-01-01').valueOf() / 1000;

    const state = reactive({
      days: '',
      hours: '',
      minutes: '',
      seconds: '',
      createAccount: false
    })

    const now = ref(0);

    const addZero = count => count < 10 ? '0'+count : count;

    watch(now,now => {
      state.seconds = addZero(parseInt(( date - now ) % 60));
      state.minutes = addZero(parseInt((( date - now) / 60 ) % 60));
      state.hours = parseInt(((date - now) / 60 / 60) % 24);
      state.days = parseInt(((date - now) / 60 / 60 / 24)); 
    })

    now.value = Date.now() / 1000;

    window.setInterval(() => now.value = Date.now() / 1000,1000);


    const register = () => state.createAccount = true;

    const removeComponent = component => state.dispatch('removeComponent',component)

    var url = location.hostname == 'localhost' ? 'ws://localhost:9494/ticket-dashboard' : 'wss://api.ticketus.net/ticket-dashboard';
    //var url = 'wss://api.ticketus.net/tickets?hello=true';
    //console.log({ url })
    WebSoc(url,{
      onopen () {
        //console.log('open !')
        WebSoc.SEND('ticket-us',{})
      },
      onmessage ( msg ) {
        console.log('onmessage',{ msg })
      }
    })

    return {
      state,
      store,
      register,
      removeComponent
    }
  }
}
</script>

<style lang="scss">
.home > .counter {
  position: absolute;
  top:0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  .row {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }  
  .block {
      display: flex;
      flex-direction: column;
      margin: 20px;
      border: 5px solid #1abc9c;
      max-width: 250px;
      min-width: 250px;
      border-radius: 20px;
      max-height: 280px;
      justify-content: center;
      align-items: center;
  }
  .text {
      color: #1abc9c;
      font-size: 40px;
      font-weight: 40;
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;
      position: relative;
      top: -25px
  }
  .digit {
      color: #ecf0f1;
      font-size: 145px;
      font-weight: 100;
      margin: 10px 10px 0px 10px;
      text-align: center;
  }
}

</style>
