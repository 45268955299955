<template>
    <div class="checkmark">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" x="0px" y="0px" style="width:120px;height:120px"
            viewBox="0, 0, 100, 100" id="checkmark" >
            <g transform="">
                <circle v-if="!props.success" class="path" fill="none" :stroke="props.color" :stroke-width="props.width" stroke-miterlimit="10" cx="50" cy="50" r="44"/>
                <circle v-if="props.success" class="fill" fill="none"  :class="{ 'fill-complete': props.success }" :stroke="props.color" :stroke-width="props.width" stroke-miterlimit="10" cx="50" cy="50" r="44"/>
                <polyline class="check" fill="none"  :class="{ 'check-complete': props.success }" :stroke="props.color" :stroke-width="props.width" stroke-linecap="round" stroke-miterlimit="5" points="70,35 45,65 30,52  "/>
            </g>
        </svg>
    </div>
</template>

<script>

export default {
    props: ['color','success','width'],
    setup ( props ) {
        
        return {
            
            props
            
        }
    },
}
</script>

<style lang="scss" scoped>
.checkmark {
    width: 100%;
    position: relative;
    z-index: 100;
    svg {
        margin: 0 auto;
        position: relative;
        left: calc(50% - 60px);
        top: calc(50% - 60px);
    }
}

.path {
  stroke-dasharray: 300;
  stroke-dashoffset: 0;
  -webkit-animation-name: load, spin;
  -webkit-animation-duration: 3s, 3s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-name: load, spin;
  animation-duration: 3s, 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50px 50px;
}

.path-complete {
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
}

.check 
{
	stroke-dasharray: 110;
	stroke-dashoffset: -110;
	stroke-width: 0;
}

.check-complete 
{   
	-webkit-animation: check 1s ease-in forwards;
	animation: check 1s ease-in forwards;
	stroke-width: 5;
	stroke-dashoffset: 0;
}

.fill 
{
	stroke-dasharray: 285;
	stroke-dashoffset: -257;
	-webkit-animation: spin-fill 3s cubic-bezier(0.700, 0.435, 0.120, 0.600) infinite forwards;
	animation: spin-fill 3s cubic-bezier(0.700, 0.465, 0.120, 0.600) infinite forwards;
    -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50px 50px;
}

.fill-complete 
{
	-webkit-animation: fill 1s ease-out forwards;
	animation: fill 1s ease-out forwards;
}

@-webkit-keyframes load {
 0% {
   stroke-dashoffset: 300;
   -webkit-animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
 }
 50% {
	 stroke-dashoffset: 0;
	 -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
 }
 100% {
   stroke-dashoffset: -300;
 }
}
@keyframes load {
 0% {
   stroke-dashoffset: 285;
   animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
 }
 50% {
	 stroke-dashoffset: 0;
	 animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
 }
 100% {
   stroke-dashoffset: -285;
 }
}

@-webkit-keyframes check {
 0% {
   stroke-dashoffset: -110;
}
 100% {
   stroke-dashoffset: 0;
 }
}
@keyframes check {
 0% {
   stroke-dashoffset: -110;
}
 100% {
   stroke-dashoffset: 0;
 }
}

@-webkit-keyframes spin {
  0% {
	-webkit-transform: rotate(0deg);
  }
  100% {
	-webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
	transform: rotate(0deg);
  }
  100% {
	transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-fill {
  0% {
	-webkit-transform: rotate(0deg);
  }
  100% {
	-webkit-transform: rotate(720deg);
  }
}
@keyframes spin-fill {
  0% {
	transform: rotate(0deg);
  }
  100% {
	transform: rotate(720deg);
  }
}

@-webkit-keyframes fill {
  0% {
	stroke-dashoffset: 285;
  }
  100% {
	stroke-dashoffset: 0;
  }
}
@keyframes fill {
  0% {
	stroke-dashoffset: 285;
  }
  100% {
	stroke-dashoffset: 0;
  }
}

.success {
    stroke: #009900;
    transition: stroke .6s;
}
</style>