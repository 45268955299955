<template>
    <p>A Test Page</p>
    <button @click="router.push('/')">HOME</button>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
    setup() {
        const router = useRouter()

        return {
            router
        }
    },
}
</script>