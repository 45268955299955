import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import Alert from './components/Alert.vue'

const app = createApp(App);

app.component('Alert',Alert);

app.use(Quasar, quasarUserOptions).use(store).use(router).mount('#app')
