<template>
    <q-dialog v-model="state.show" persistent>        
    <q-card style="width:800px;min-width: 800px;">
        <q-card-section class="q-pt-xs" style="padding-bottom:0px">
            <div class="text-h6 q-mt-sm q-mb-xs">Create Account</div>
            <q-btn style="position:absolute;top:0px;right:0px;z-index:100" flat round dense icon="close" @click="exit({ exit: true })" />
        </q-card-section>
        <q-card-section> 
            <div class="row cols-2">
                <q-input filled class="q-ma-md col" v-model="state.firstName" label="First name" />
                <q-input filled class="q-ma-md col" v-model="state.lastName" label="Last name" />
            </div>
            <div class="row cols-2">
                <q-input filled class="q-ma-md col" v-model="state.email" @blur="state.isEmail = !state.email || isEmail(state.email)" label="Email address">
                    <template v-slot:append v-if="!state.isEmail">
                        <div style="position:relative">
                            <q-icon name="warning" color="red"></q-icon>
                            <div class="text-caption absolute text-red" style="left:-6px;top:24px;transform:scale(0.7)">invalid</div>
                        </div>
                    </template>
                </q-input>
                <IntTelInput @updatePhone="updatePhone" class="q-ma-md col" :msisdn="state.msisdn" label="Phone Number"/>
            </div>
            <div class="row cols-2" style="">
                <q-input id="company" filled class="q-ma-md col" v-model="state.organisation" label="Organisation" />
            </div>
            <q-separator />
            <q-card-section class="flex justify-end">
                <q-btn label="create account" color="primary" @click="createAccount" style="height:43px;width:186px"/>
            </q-card-section>
        </q-card-section>
        <div style="position:absolute;top:0px;width:100%;height:100%;background-color: white;z-index: 300;" v-if="state.creatingAccount">
            <q-btn style="position:absolute;top:0px;right:0px;z-index:200" flat round dense icon="close" @click="state.creatingAccount = false" />
            <div class="text-h6 q-mt-sm q-mb-lg text-center">{{ state.creatingAccount.title }}</div>
            <PendingCheck :width="4" color="green" :success="state.creatingAccount.success" style="width:100%;height:130px;margin-top:30px" />
            <q-card-section class="row q-ma-sm" style="margin:0px;width:100%">
                <div v-if="state.creatingAccount.mobileMessage" class="row q-ma-md" style="margin-bottom:0px;margin-top:0px;width: 100%;">
                    <div class="text-body2 col-8 q-ma-md" style="margin-top:23px">{{ state.creatingAccount.mobileMessage }}</div>
                    <q-btn class="col-2 q-ma-md" label="resend SMS" color="primary" style="width:175px" @click="resend('sms')"/>
                </div>
                <div v-if="state.creatingAccount.emailMessage" class="row q-ma-md" style="margin-bottom:0px;margin-top:0px;width: 100%;">
                    <div class="text-body2 col-8 q-ma-md" style="margin-top:23px">{{ state.creatingAccount.emailMessage }}</div>
                    <q-btn class="col-2 q-ma-md" label="resend eMail" color="primary"  style="width:175px"  @click="resend('email')" />
                </div>
                <div class="row q-ma-md" style="width:100%">
                    <div class="col-12 text-center">To manage your new site go to <a :href="state.creatingAccount.url">{{ state.creatingAccount.url }}</a></div>
                </div>
            </q-card-section>

        </div>
    </q-card>
</q-dialog>
</template>

<script>

import { reactive, onMounted, nextTick } from 'vue'
import isEmail from '../utils/isEmail.js'
import { WebSoc } from '../utils/Comms.js';
import IntTelInput from '../components/IntTelInput.vue';
import { message } from '../utils/Alert.js'
import PendingCheck from '../components/pendingCheck.vue'

export default {
components: { IntTelInput, PendingCheck },
setup ( props, context ) {
    const state = reactive({
        show: false,
        firstName: '',
        lastName: '',
        organisation: '',
        email: '',
        isEmail:  true,
        phone: '',
        msisdn: '',
        pending: true,
        creatingAccount: false,
    });

    setTimeout(() => {
        state.pending = false
    },5000)

    onMounted(() => {
        state.show = true;
    })

    const updatePhone = change => {
        Object.keys(change).forEach(key => {
            state[key] = change[key]
        })
    }

    const exit = () => {
        state.show = false;
        setTimeout(() => context.emit('exit'),500);
    }

    const createAccount = () => {
        state.creatingAccount = { 
            title: 'Creating Account', 
            success: false
        };
        WebSoc.SEND('createAccount',{ account: state },resp => {
            console.log('createAccount',{ resp });
            resp.message && message(resp.message);
            resp.creatingAccount && ( state.creatingAccount = resp.creatingAccount );
            resp.success && ( state.creatingAccount.success = resp.success );
        })
    }

    const resend = type => {
        WebSoc.SEND('reSend',{ type, creatingAccount: state.creatingAccount },resp => {
            resp.message && message(resp.message);
        })
    }

    return {
        state,
        exit,
        updatePhone,
        isEmail,
        createAccount,
        resend
    }

},
}
</script>