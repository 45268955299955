import { createStore } from 'vuex'

export default createStore({
  state: {
    components: []
  },
  getters: {
  },
  mutations: {
    setValue ( state, data ) {
      Object.keys(data).forEach(key => state[key] = data[key]);
    },
    addComponent ( state, component ) {
      state.components.push(component);
    },
    removeComponent ( state, component ) {
        state.components = state.components.filter(c => c != component);
    },
    addQuillToolbar ( state, $q ) {
      const { toolbar, fonts } = addQuill($q);
      state.quill.toolbar = toolbar;
      state.quill.fonts = fonts;
    }
  },
  actions: {
    addComponent ({ commit, state },{ component, data, complete, added, exit }) {
      console.log('addComponent',{ component })
      data.added = Date.now() * ( state.components.length + 1);
      data.complete = complete;
      data.exit = () => { 
        //const components = state.components.filter(c => c.data.added != data.added);
        //console.log('retained components',components)
        commit('removeComponent',component);
        typeof exit == 'function' && exit();
        //setTimeout(() =>  components.forEach(c => commit('addComponent',c)),100)  
      }
      component = { component, data }
      commit('addComponent',component);
      typeof added == 'function' && added(data.added);
    },
  },
  modules: {
  }
})
