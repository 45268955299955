import store from "@/store";

export const Alert = ( data, cB ) => {

    console.log({ store })

    store.dispatch('addComponent',{ 
        component: 'Alert',
        data: {
            message: data.message,
            cancel: data.cancel,
            ok: data.ok,
            input: data.input
        },
        complete: cB
    })
}

Alert.message = (message, cB) => {

    Alert({
        message,
        cancel: '',
        ok: 'OK'
    },cB)
}

Alert.yesno = (message, cB) => {
    Alert({
        message,
        cancel: 'no',
        ok: 'yes'
    },cB)
}

Alert.prompt = ( message, cB ) => {
    if ( typeof message == 'function' ) {
        cB = message; message = ''
    }
    Alert({
        message,
        placeholder: '',
        cancel: '',
        ok: 'OK',
        input: ''
    },cB)
}
  
export const message = message => Alert.message(message)