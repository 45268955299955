<template>
    <div>
        <q-dialog persistant v-model="show">
            <q-card style="width:500px">
                <q-card-section class="q-pt-xs" style="padding-bottom:0px">
                     <div class="text-h6 q-mt-sm q-mb-xs">{{ header }}</div>
                    <q-btn style="position:absolute;top:0px;right:0px;z-index:100" flat round dense icon="close" v-close-popup @click="exit($event)" />
                </q-card-section>
                <q-separator />
                <div style="padding:20px;">
                    <div v-if="data.message" class="text q-mt-sm q-mb-xs" style="text-align:center;font-size:20px" v-html="message"></div>
                    <div v-if="data.options">
                        <q-select :label="data.label" v-model="option" :options="data.options"></q-select>
                    </div>
                    <q-input v-if="typeof input == 'string'" v-model="input" style="font-size:20px" :placeholder="placeholder" />
                </div>
                <q-separator />
                <q-card-actions style="display:flex;justify-content: space-between;;width:100%">
                    <q-btn color="grey" :style="{ opacity: cancel ? 1 : 0 }" @click="OK(false)" style="width:120px">{{ cancel }}</q-btn>
                    <q-btn color="accent" style="width:120px" @click="OK($event,true)">{{ ok }}</q-btn>
                </q-card-actions>
            </q-card>
        </q-dialog>
    </div>
</template>

<script>

//import { DB } from 'src/utils/Comms.js'

export default {
    props: ['data'],
    data () {
        return {
            show: true,
            ok: 'OK',
            cancel: 'Cancel',
            message: '',
            header: 'Message',
            option: null,
            input: null,
            placeholder: null
        }
    },
    created () {
        console.log('Alert.js',this.data)
        var data = this.data;
        ['ok','cancel','header','message','input','placeholder'].forEach(key => {
            typeof data[key] != 'undefined' && ( this[key] = data[key] );
        });
    },
    methods: {
        OK ( e, response ) {
            typeof this.data.complete == 'function' && this.data.complete(this.input || this.option || response);
            this.exit( e );
        },
        exit ( e ) {
            e && e.stopPropagation();
            e && e.preventDefault();
            this.show = false;
            setTimeout(() => this.$emit('exit'),400);
        }
    }
}

</script>