<template>
    <div style="position:relative;" class="i-tel-container">
        <q-form class="i-tel-2">
            <q-input filled ref="msisdnInput"  :label="state.label" v-model="state.msisdn" @blur="checkPhone">
                <template v-slot:append v-if="!state.isValid">
                    <div style="position:relative">
                        <q-icon name="warning" color="red"></q-icon>
                        <div class="text-caption absolute text-red" style="left:-6px;top:24px;transform:scale(0.7)">invalid</div>
                    </div>
                </template>
            </q-input>
        </q-form>
    </div>
</template>

<script>

import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import { onMounted, reactive, ref } from 'vue';

export default {
    props: ['msisdn','label'],
    setup( props, context ) {

        const msisdnInput = ref(null);
        const state = reactive({
            isValid: true,
            msisdn: props.msisdn,
            label: props.label
        });

        let iti;

        onMounted(() => {
            iti = intlTelInput(document.body.querySelector('.i-tel-2 input'),{
                placeholderNumberType: "Phone Number",
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js",
                preferredCountries: ['us','gb','ca']
             });
    
             watchUpdates();
    
        });

        const watchUpdates = () => {
            if ( !window.intlTelInputGlobals ) { return setTimeout(() => watchUpdates(),500); };
            updateCountryCode()
        }

        const checkPhone = () => {
            state.isValid = iti.isValidNumber();
            context.emit('updatePhone',{
                isValidPhone: state.isValid,
                country: iti.getSelectedCountryData()?.iso2,
                msisdn: iti.getNumber().replace('+',''),
                phone: iti.getNumber(2),
                isPhoneMobile: iti.getNumberType() == window.intlTelInputUtils.numberType.MOBILE
            });
        }

        const updateCountryCode = () => {

            var countryData = window.intlTelInputGlobals.getCountryData().filter(country => state.msisdn.startsWith(country.dialCode)).sort((a,b) => a.priority < b.priority ? -1 : 1).reduce((p,country,i,arr) => {
                        var local = state.msisdn.replace(country.dialCode,'');
                        if ( !Array.isArray(country.areaCodes) ) { 
                            return country 
                        } else if ( country.areaCodes.find(area => local.startsWith(area)) ) {
                            return country
                        }
                        return p;
                    },{});

                countryData.iso2 && iti.setCountry(countryData.iso2);
        }

        return {
            msisdnInput,
            checkPhone,
            state
        }
    },
}
</script>

<style lang="scss">
.i-tel-container {
    .q-field--labeled .iti {
    width: 100%;
    .iti__selected-flag {
        height: 60%;
        margin-top: calc(40% + 0px);
    }
    + .q-field__label {
        margin-top: -10px;
        margin-left: 8px;
    }
    .q-field-float {
        background-color: red;
    }

}
.q-field--float .q-field__label {
    margin-left: 8px !important;
    margin-top: 0px !important; 
}
.q-field--float {
    .q-field__label {
        margin-top: -100px;
    }
}
}

</style>